<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'EventList' }" >{{ '活動管理' }} </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'EventList' }" >{{ '活動列表' }} </b-breadcrumb-item>
            <b-breadcrumb-item active>活動會員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">活動會員列表</h4>
        <div class="col-12 col-xl-6 mb-2">
          <div class=" d-flex flex-column flex-xl-row align-items-start align-items-xl-center">
            <b-button
              class="flex-shrink-0 mb-2 mb-xl-0"
              variant="primary"
              @click="exportCustomers"
            >
              匯出結果
            </b-button>
            <b-input-group class="mb-2 mb-xl-0 ml-0 ml-xl-4">
              <b-form-input
                v-model="keyword"
                @keyup.enter="getCustomerList"
                placeholder="搜尋姓名、平台id(outer_code)"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="getCustomerList"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
      <div
        class="
          d-flex mb-3
          flex-column flex-xl-row
          align-items-start align-items-xl-center
        "
      >
        <span class="no-wrap">申請區間：</span>
        <datepicker
          placeholder="Select Date"
          bootstrap-styling
          format="yyyy-MM-dd"
          v-model="startAt.date"
        ></datepicker>
        <vue-timepicker class="ml-2" v-model="startAt.time"></vue-timepicker>
        <span class="ml-3 mr-2">~</span>
        <datepicker
          placeholder="Select Date"
          bootstrap-styling
          format="yyyy-MM-dd"
          v-model="endAt.date"
        ></datepicker>
        <vue-timepicker class="ml-2" v-model="endAt.time"></vue-timepicker>
        <b-button class="ml-2" @click="getCustomerList"><i class="fa fa-search"></i></b-button>
      </div>
      <div class="d-flex flex-column mb-3">
        <p>申請區間：{{ displayValue.startAt }} {{ displayValue.startAt ? '~' : '' }} {{ displayValue.endAt }}</p>
        <p>關鍵字：{{ displayValue.keyword }}</p>
        <p>查詢結果：共 {{ totalRows }} 筆</p>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="customers"
            :fields="fields"
            :busy="isFetchCustomerList"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import eventApi from "@/apis/event";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { format, addMonths, subMonths, set } from "date-fns";

export default {
  components: { Datepicker, VueTimepicker },

  data() {
    const baseFields = [
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "outer_code",
          label: "平台ID",
        },
    ];

    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      startAt: {
        date: subMonths(new Date(), 1),
        time: { HH: "00", mm: "00", },
      },
      endAt: {
        date: addMonths(new Date(), 1),
        time: { HH: "23", mm: "59", },
      },
      keyword: null,
      isFetchCustomerList: false,
      customers: [],
      baseFields: baseFields,
      fields: baseFields,
      displayValue: {
        startAt: null,
        endAt: null,
        keyword: null,
      }
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  watch: {
    currentPage() {
      this.getCustomerList();
    }
  },

  created() {
    this.getCustomerList();
  },

  methods: {
    async getCustomerList() {
      try {
        this.isFetchCustomerList = true;
        const params = {
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
          start_at: this.formatDateTimeString(this.startAt, 0),
          end_at: this.formatDateTimeString(this.endAt, 59),
        }
        const { data } = await eventApi.customerList(this.$route.params.event_id, params);
        this.fields = this.baseFields.concat(this.normalizeProfileFeilds(data.meta.event_profile_mappings || []));
        this.customers = data.data;
        this.totalRows = data.meta.total;
        this.displayValue = {
          startAt: params.start_at,
          endAt: params.end_at,
          keyword: params.keyword
        }
      } catch (e) {
        console.log(e);
      }
      this.isFetchCustomerList = false;
    },
    formatDateTimeString(datetime, seconds) {
      return format(
        set(new Date(datetime.date), {
          hours: Number(datetime.time.HH),
          minutes: Number(datetime.time.mm),
          seconds: seconds,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
    normalizeProfileFeilds(profileMappings) {
      return profileMappings.map(mapping => {
        return {
          key: `siblings.${mapping.key}`,
          label: mapping.display_name,
        }
      });
    },
    exportCustomers() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}` +
        `/events/${this.$route.params.event_id}/customers/export?token=${store.state.auth.token}`;

      url = `${url}&start_at=${this.formatDateTimeString(this.startAt, 0)}&end_at=${this.formatDateTimeString(this.endAt, 59)}`;
      if (this.keyword) url = url + "?keyword=" + this.keyword;

      window.open(url);
    },
  },
};
</script>

<style lang="scss">
.vdp-datepicker {
  min-width: 10rem;
}
</style>
