import https from "./https"
import store from "@/store"

const eventApi = {
  getEvents (organization, params) {
    return https.get(`admin/organizations/${organization.id}/events`, { params })
  },
  getEvent (organization, eventId) {
    return https.get(`admin/organizations/${organization.id}/events/${eventId}`)
  },
  updateEvent (organization, params) {
    return https.put(`admin/organizations/${organization.id}/events/${params.id}`, params)
  },
  removeEvent (organization, params) {
    const url = 'admin/organizations/' + organization.id + '/events/' + params.id;
    return https.delete(url)
  },
  storeEvent (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/events'

    return https.post(url, params)
  },
  getRedeemCodes (organization, eventId, params) {
    return https.get(`admin/organizations/${organization.id}/events/${eventId}/redeem-codes`, { params })
  },
  imoprtRedeemCode (organization, params, formData) {
    const url = `admin/organizations/${organization.id}/events/${params.id}/redeem-codes/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  customerList (eventId, params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/events/${eventId}/customers`, { params })
  }
}

export default eventApi
